import { createRoot } from 'react-dom/client';
import Contact from 'computing-den-lib/dist/client/Contact.jsx';
import Navbar from 'computing-den-lib/dist/client/Navbar.jsx';
import Author from 'computing-den-lib/dist/client/Author.jsx';
import * as I from 'computing-den-lib/dist/client/Icon.jsx';
import React, { useState } from 'react';

function setup() {
  const root = createRoot(document.getElementById('app')!);
  root.render(<App />);
}

window.onload = setup;

function App() {
  return (
    <>
      <Navbar
        transparentAtTop
        links={[{ label: 'Contact', href: '/#contact', primary: true }]}
        breadcrumb={{ label: 'CodeMic', href: '/' }}
      />
      <Intro />
      <Slides />
      <Prose />
      <Contact />
    </>
  );
}

function Intro() {
  return (
    <section id="intro">
      <div className="content">
        <h1 className="section-heading">CodeMic</h1>
        <h3 className="slogan">Beyond screencasts: record & replay your IDE</h3>
        <iframe id="ytplayer" src="https://www.youtube.com/embed/Qp2GdLO5eSc" frameBorder="0" allowFullScreen />

        <Subscribe forIntro />
      </div>
    </section>
  );
}

function Slides() {
  return (
    <section id="slides">
      <div className="content">
        <Slide image="/screenshot1.png">
          <h2>
            <span className="step">STEP 1</span>
            <br />
            Pick a Session
          </h2>
        </Slide>

        <Slide image="/screenshot2.png">
          <h2>
            <span className="step">STEP 2</span>
            <br />
            Hit Play
          </h2>
        </Slide>

        <Slide image="/screenshot3.png">
          <h2>
            <span className="step">STEP 3</span>
            <br />
            Sit Back & Watch
          </h2>

          <p>
            CodeMic will download the repository and replay every click, scroll, and keystroke inside your IDE while
            keeping the workspace in sync during playback.
          </p>

          <p>Follow a guide through thousands or millions of lines of code.</p>
          <p>
            Pause, <b>explore</b>, and <b>experiment</b> inside your IDE.
          </p>
        </Slide>

        <Slide image="/screenshot4.png">
          <h2>
            <span className="step">STEP 4</span>
            <br />
            Create & Publish
          </h2>

          <p>Record your interactions with the IDE.</p>
          <p>See an overview of all the changes in the timeline.</p>
          <p>
            Add <b>video</b>, <b>audio</b>, and <b>images</b>.
          </p>
          <p>Easily publish your session with everyone.</p>
          <p>Create a deep dive into a large codebase or show how to build a complex program from scratch.</p>
        </Slide>
      </div>
    </section>
  );
}

function Prose() {
  return (
    <section id="prose" className="prose">
      <div className="content">
        <h3 className="section-subheading">THE STORY</h3>
        <h1 className="section-heading">Introducing CodeMic</h1>

        <h2>Crazy Beetles</h2>
        <p>
          A few years back, I came across an insightful article by Peter Seibel titled{' '}
          <a className="styled" href="https://gigamonkeys.com/code-reading/" target="_blank">
            <i>Code is not Literature.</i>
          </a>{' '}
          He wrote about a peculiar observation: everyone says reading code is important, yet few of us actually do it.
          I highly recommend reading the entire article, but here’s the punchline that’s stuck with me for years and
          occupied much of my time this past year:
        </p>
        <blockquote>
          <p>
            But then it hit me. Code is not literature and we are not readers. Rather, interesting pieces of code are
            specimens and we are naturalists. So instead of trying to pick out a piece of code and reading it and then
            discussing it like a bunch of Comp Lit. grad students, I think a better model is for one of us to play the
            role of a 19th century naturalist returning from a trip to some exotic island to present to the local
            scientific society a discussion of the <b>crazy beetles</b> they found: “Look at the antenna on this
            monster! They look incredibly ungainly but the male of the species can use these to kill small frogs in
            whose carcass the females lay their eggs.”
            <br /> —<b>Peter Seibel</b>
          </p>
        </blockquote>

        <p>
          This made me wonder: how do we present and discuss code? I believe it depends on the size and complexity of
          the code:
        </p>

        <ul className="styled">
          <li>
            <b>10s of lines:</b> Short snippets that fit into slides are great for presentations.
          </li>
          <li>
            <b>100s of lines:</b> Blog posts or books work well for this size.
          </li>
          <li>
            <b>1000s of lines:</b> Screencasts may be the best fit, but even they fall short.
          </li>
        </ul>

        <p>
          Screencasts, in some ways, are like podcasts—a medium that’s exploded in popularity for long-form content.
          Podcasts work wonderfully for topics like science, history, or comedy, but code is different because, well,{' '}
          <i>code is not literature.</i>
        </p>

        <p>
          Code is not literature in the sense that we can't read it top to bottom in a linear fashion like we would a
          novel. As Peter pointed out, code is something to be decoded, studied, and examined, not simply read. Yet,
          none of the mediums we currently have allow us to do that effectively with a guide. Except for live
          presentations, they're all static. You can't even scroll up to see what came before a piece of code or what
          else is in the file. They present code in isolated fragments, out of context, and outside our primary tool for
          understanding, exploring, and experimenting: <b>our IDE</b>.
        </p>

        <h2>Show me the code in MY IDE, not yours!</h2>

        <p>CodeMic offers a better way to:</p>

        <ul className="styled">
          <li>Create guided tours of existing codebases.</li>
          <li>Follow a guide building a complex project from scratch.</li>
        </ul>

        <p>
          CodeMic’s approach is different. It brings both the guide and the code into your IDE, where you can explore
          and experiment. Code isn’t dead pixels on a screen or paper anymore. It becomes a living specimen you can
          interact with. You see it evolve in real time while following a video, audio, or text guide along with visual
          aids. The experience is transformative, unlike anything we’ve had before.
        </p>

        <p>
          <b>
            Unlike screencasts, CodeMic records your interactions with the IDE: every click, scroll, selection, and
            modification. This enables an experience screencasts can't match.
          </b>
        </p>

        <p>
          Take{' '}
          <a className="styled" href="https://hero.handmade.network/" target="_blank">
            Handmade Hero
          </a>{' '}
          for example—a series where Casey Muratori builds an entire game from scratch. It’s one of my all-time
          favorites and an invaluable programming resource for game and system developers. Today, it has 667 episodes,
          each lasting between 1 to 4 hours. To help navigate this enormous amount of content, the community built an{' '}
          <a className="styled" href="https://guide.handmadehero.org/" target="_blank">
            Episode Guide
          </a>
          , a synchronized table of contents with search functionality that allows you to find specific topics Casey
          discusses. This tool has been incredibly helpful for exploring thousands of hours of material.
        </p>

        <p>
          Yet, even with the Episode Guide, I often find it difficult to follow along and sometimes feel lost in the
          middle of an episode. Sometimes, I wish I could reach into the video and hover the mouse over a symbol to see
          its definition or scroll the editor to see the previous code. I wish for a more interactive and structured
          experience with better search and navigation, something beyond what YouTube’s interface can provide when
          navigating code-heavy content.
        </p>

        <p>
          CodeMic, inspired by the Handmade Hero community, takes the Episode Guide a step further. Not only can you
          search through the table of contents, but you can jump to the exact moment in the right video where a specific
          line of code was written or discussed. Want to know exactly when function <code>foo()</code> was created or
          file <code>bar.c</code> was opened? Ask CodeMic inside your IDE. Best of all, this information is extracted
          automatically in a similar way to <code>git blame</code> and is possible for the same exact reason:{' '}
          <b>CodeMic doesn’t record pixels on the screen, it records the content diffs and actions.</b>
        </p>

        <p>
          When recording, you can either start by scanning your workspace directory or pick a remote Git repo (e.g.,
          GitHub or GitLab). This also means that for any Git project, you can see all related sessions and take guided
          tours through the codebase. This is not just a new form of documentation, it's a more effective way to onboard
          developers. The experience is far from giving them a wall of text to read or letting them jump into the code
          with little guidance. It’s more like having the project’s graybeard sitting next to you, walking you through
          the code inside your own IDE.
        </p>

        <h2>Why Not Books or Blogs?</h2>

        <p>
          Books work great for small blocks of code, but it’s difficult to write a book that dives into a large
          codebase. Many lines get omitted, and code snippets are presented out of context.
        </p>

        <p>
          I’ve read such books and while I can tell the author is trying hard, it often feels like they’re squeezing a
          large, complex picture through a narrow bandwidth. It’s like reading about those crazy beetles instead of
          seeing one up close.
        </p>

        <p>
          The challenge is even greater for <b>build-X-from-scratch</b> type of tutorials. Authors must not only
          describe the code, but also how it evolves. Adding a feature might mean modifying multiple functions and
          structures. These changes are often presented as diffs and the reader must keep the previous version in their
          head while mentally applying the patches. <b>It’s hard to visualize how the code changes as a whole.</b>
        </p>

        <p>
          It’s doable, of course. With enough skill and patience, an author can explain a complex program in fragments.{' '}
          <a className="styled" href="https://craftinginterpreters.com/" target="_blank">
            Crafting Interpreters
          </a>{' '}
          is one of my favorites for this. The author does an incredible job showing the design, implementation, and
          evolution of an interpreter. But even then, adding something like an <code>if-else</code> statement to the
          interpreter required 17 snippets—some new and some patches to be applied to code from previous chapters.
        </p>

        <p>
          Since reading Crafting Interpreters, I've been searching for more resources where I could follow someone
          building a project from scratch. The{' '}
          <a className="styled" href="https://github.com/codecrafters-io/build-your-own-x" target="_blank">
            build-your-own-x
          </a>{' '}
          GitHub repo is a treasure trove for building everything from databases to game engines. However, these
          resources still follow the same general format:{' '}
          <b>
            code snippets presented out of context and outside your IDE, which limits exploration and experimentation.
          </b>
        </p>

        <h2>Release and the Future of CodeMic</h2>

        <p>
          I’ve been working on CodeMic for the past year. It’s been a journey to find the right design and use cases. I
          plan to release it in <b>early 2025</b>. If you'd like early access and progress updates, please leave your
          email below.
        </p>

        <p>
          In future versions, I plan to bring CodeMic to more IDEs: <b>Emacs</b>, <b>(Neo)Vim</b>, <b>IntelliJ</b>,{' '}
          <b>Sublime Text</b>, <b>Helix</b>, and others. It’d also be great to have a web player or mobile app for
          smaller screens (unlike screencasts, the font size can adapt to the screen).
        </p>

        <p>
          CodeMic is a platform for us programmers to share our expertise and findings. It is for hobbyists and
          professionals alike to create long-form guides in a new market. I hope to see more Handmade Heroes and experts
          Crafting Interpreters.
        </p>

        <p>
          I dream of exploring the incredible open-source jungle seeing the crazy beetles and all the mysterious
          creatures within.
        </p>

        <p>
          <i>
            To the inspired,
            <br />
            the explorers and the adventurers,
            <br />
            the creators and the curious.
            <br />
          </i>
        </p>

        <Author timestamp="2024-10-09T12:00:00.000Z" />
        <Subscribe />
      </div>
    </section>
  );
}

function Slide(props: { image: string; children: React.ReactNode }) {
  return (
    <div className="slide">
      <div className="desc">{props.children}</div>
      <img src={props.image} />
    </div>
  );
}

function Subscribe(props: { forIntro?: boolean }) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<{ type: 'success' | 'error'; text: string }>();
  const [sending, setSending] = useState(false);

  async function send(e: React.MouseEvent | React.KeyboardEvent) {
    e.stopPropagation();
    e.preventDefault();

    try {
      setMessage(undefined);
      setSending(true);
      const res = await fetch('/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      if (res.ok) {
        setMessage({
          type: 'success',
          text: `Subscribed ${email}. You’ll receive an email as soon as CodeMic early access is available.`,
        });
      } else {
        setMessage({ type: 'error', text: await res.text() });
      }
    } catch (error) {
      console.error(error);
      setMessage({ type: 'error', text: (error as Error).message });
    } finally {
      setSending(false);
    }
  }

  function keyPressed(e: React.KeyboardEvent) {
    if (e.key === 'Enter') send(e);
  }

  return (
    <div className={`subscribe ${props.forIntro ? 'for-intro' : ''}`}>
      {message?.type !== 'success' && (
        <div className="input-container">
          <input
            type="text"
            placeholder="name@example.com"
            value={email}
            onChange={e => setEmail(e.target.value)}
            disabled={sending}
            onKeyDown={keyPressed}
          />
          {/*<button type="button" className="left">
              <I.IconEmail />
              </button>*/}
          <button type="button" className="right" onClick={send} disabled={!email}>
            <I.IconChevronRight />
          </button>
        </div>
      )}

      {message && <p className={`message ${message.type}`}>{message.text}</p>}
      {message?.type !== 'success' && (
        <p className="message">
          Subscribe for <b>early access</b> and monthly progress updates.
        </p>
      )}
      <p className="message">
        <a className="styled" href="https://x.com/SeanShirazi" target="_blank">
          Stay tuned on <b>𝕏</b> for launch news.
        </a>
      </p>
    </div>
  );
}
